<template>
  <v-row class="mt-3">
    <v-col>
      <v-alert text type="warning">
        <v-row align="center">
          <v-col class="grow">
            This order has been invoiced as
            <a
              @click="pushInvoiceData(invoice.invoice_id)"
              style="text-decoration: underline"
              >{{ invoice.invoice_number }}</a
            >
            and cannot be updated or deleted. To add additional items, create a
            new order for this customer.
          </v-col>
          <v-col class="shrink">
            <v-btn @click="createAdditionalSalesOrder()"
              >Create Additional Order</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "SalesOrderInvoicedAlert",
  props: ["customer", "invoice"],
  data() {
    return {};
  },
  methods: {
    pushInvoiceData(invoice_id) {
      const routeData = this.$router.resolve({
        path: `view-invoices/${invoice_id}`,
      });
      window.open(routeData.href, "_blank");
    },
    //
    createAdditionalSalesOrder() {
      this.$router.push({
        name: "new-sales-order",
        query: {
          customer: this.customer,
        },
      });
    },
  },
};
</script>